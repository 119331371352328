/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useMemo } from "react";

// react-router components
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
// Material Dashboard 2 React example components
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";

// Material Dashboard 2 React themes
import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

// Material Dashboard 2 React Dark Mode themes
import themeDark from "assets/theme-dark";
import themeDarkRTL from "assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Material Dashboard 2 React routes
import routes from "routes";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// Material Dashboard 2 React contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Config from 'config'
// Images
import brandWhite from "assets/images/1.png";
import brandDark from "assets/images/payhub-black.jpg";
import { toast } from 'react-toastify';
export default function App() {
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { supportUrl } = Config
  const supportToken = localStorage.getItem('support_token')
  const [financeQueryNotification, setFinanceQueryNotification] = useState('')
  const [technicalQueryNotification, setTechnicalQueryNotification] = useState('')
  // Check if user is authenticated
  const navigate = useNavigate(); // Hook for programmatic navigation
  const { pathname } = useLocation();
  useEffect(() => {
    const token = localStorage.getItem('support_token');
    if (token) {
      // Redirect to dashboard if user is authenticated and trying to access /authentication/sign-in
      if (window.location.pathname === '/authentication/sign-in') {
        navigate('/dashboard');
      }
    } else {
      // Redirect to sign-in page if user is not authenticated
      navigate('/authentication/sign-in');
    }
  }, [navigate]);
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);

  }, []);
  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Change the openConfigurator state
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const getFinanceNotification = async () => {
    try {
      const response = await fetch(`${supportUrl}/api/chat/support/message/pending?category=transaction`, {
        method: 'GET',
        headers: {
          "Authorization": supportToken,
          'Content-Type': 'application/json',
        },
      })

      if (!response) return;
      const res = await response.json()
      if (res.responseCode !== 200) return;
      // if (res?.responseData?.totalMerchantMessageCount !== 0 && financeQueryNotification.totalMerchantMessageCount < res.responseData?.totalMerchantMessageCount) {
      //   toast.success("New message recieved")
      // }
      setFinanceQueryNotification(res?.responseData)
    } catch (err) {
      console.log("Error Fetching getFinanceNotification: ", err)
    }
  }
  const getTechicalNotification = async () => {
    try {
      const response = await fetch(`${supportUrl}/api/chat/support/message/pending?category=technical`, {
        method: 'GET',
        headers: {
          "Authorization": supportToken,
          'Content-Type': 'application/json',
        },
      })
      if (!response) return;
      const res = await response.json()
      if (res.responseCode !== 200) return;
      setTechnicalQueryNotification(res.responseData)
    } catch (err) {
      console.log("Error Fetching getTechicalNotification: ", err)
    }
  }
  useEffect(() => {
    getFinanceNotification()
    getTechicalNotification()
    const timerId = setInterval(() => {
      getFinanceNotification();
      getTechicalNotification()
    }, 2000);

    // Clean up the timer on component unmount
    return () => clearInterval(timerId);
  }, [])
  const updatedRoutes = routes.map(route => {
    if (route.key === "assigned-finance-queries") {
      return { ...route, notificationCount: financeQueryNotification.totalMerchantMessageCount };
    }
    if (route.key === "assigned-technical-queries") {
      return { ...route, notificationCount: technicalQueryNotification.totalMerchantMessageCount };
    }
    return route;
  });
  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="small" color="inherit">
        settings
      </Icon>
    </MDBox>
  );

  return direction === "rtl" ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        {layout === "dashboard" && (
          <>
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName="PAYHUB ADMIN"
              routes={updatedRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <Configurator />
            {configsButton}
          </>
        )}
        {layout === "vr" && <Configurator />}
        <Routes>
          {getRoutes(updatedRoutes)}
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {layout === "dashboard" && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
            brandName="PAYHUB ADMIN"
            routes={updatedRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>
        {getRoutes(updatedRoutes)}
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
      <ToastContainer
        hideProgressBar={false}
        autoClose={2000}
        limit={1}
      />
    </ThemeProvider>

  );
}
