/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
// Images
import { toast } from "react-toastify";
import Config from "../../../config";
export default function data(queries, getQueries) {
    const { supportUrl } = Config
    const supportToken = localStorage.getItem("support_token")
    const attachments = (attachment) =>
        attachment.map((image, index) => (

            <MDAvatar
                key={index}
                src={image?.imageUrl}
                alt="name"
                size="xs"
                sx={{
                    border: ({ borders: { borderWidth }, palette: { white } }) =>
                        `${borderWidth[2]} solid ${white.main}`,
                    cursor: "pointer",
                    position: "relative",

                    "&:not(:first-of-type)": {
                        ml: -1.25,
                    },

                    "&:hover, &:focus": {
                        zIndex: "10",
                    },
                }}
                onClick={() => {
                    window.open(image?.imageUrl, '_blank');
                }}
            />

        ));

    const Txid = ({ id }) => (
        <MDBox display="flex" alignItems="center" lineHeight={1}>
            <MDTypography variant="button" fontWeight="medium" lineHeight={1}>
                {id}
            </MDTypography>
        </MDBox>
    );

    async function AssignQueryToMe(queryId) {
        try {
            const response = await fetch(`${supportUrl}/api/issue/support/assign/${queryId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': supportToken
                }
            })
            if (!response) console.log("Something went wrong")
            const res = await response.json()
            if (res?.responseCode === 200) {
                toast.success(res?.responseMessage)
                getQueries()
            } else {
                toast.error(res?.responseMessage)
            }
        } catch (err) {
            console.log("Error AssignQueryToMe: ", err)
        }
    }

    return {
        columns: [
            { Header: "Ticket No.", accessor: "ticket", align: "left" },
            { Header: "subject", accessor: "subject", width: "30%", align: "left" },
            { Header: "attachments", accessor: "attachments", width: "30%", align: "left" },
            { Header: "action", accessor: "action", align: "center" },
        ],
        rows: queries && queries.map((item, index) => ({
            ticket: <Txid id={item?.ticketNumber || ''} />,
            subject: <Txid id={item?.subject} />,
            attachments: (
                <MDBox display="flex" py={1}>
                    {attachments(
                        item?.images
                    )}
                </MDBox>
            ),
            action: (
                <MDTypography onClick={() => {
                    AssignQueryToMe(item?._id)
                }} component="a" href="#" variant="caption" color="info" fontWeight="medium">
                    Assign me
                </MDTypography>
            ),
        }))

    }

}