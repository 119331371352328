/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { useState, useEffect } from "react";
import Config from "../../config"
import Loader from "util/Loader";
function Dashboard() {
  const { supportUrl } = Config
  const [cardsData, setCardsData] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const supportToken = localStorage.getItem('support_token')
  async function getCardsData() {
    try {
      const response = await fetch(`${supportUrl}/api/support/analytics`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': supportToken
        }
      })
      if (!response) console.log("Something went wrong")
      const res = await response.json()
      setCardsData(res?.responseData)
    } catch (err) {
      console.log("Error Fetching getCardsData: ", err)
    }
  }
  useEffect(() => {
    getCardsData().then(() => {
      setIsLoading(false)
    })
  }, [])
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {
        isLoading ? (<Loader />) : (
          <MDBox py={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="7FDF9A"
                    title="Today's Queries"
                    count={cardsData?.todayQuery}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    title="Yesterday's Queries"
                    color="D4BEF0"
                    count={cardsData?.yesterdayQuery}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="BBF2EA"
                    title="Open Queries"
                    count={cardsData?.openQuery}
                  />
                </MDBox>
              </Grid>

              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="FFFED2"
                    title="Assigned Queries"
                    count={cardsData?.assignedQuery}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={2.4} lg={2.4}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="C9DEDE"
                    title="Resolved Queries"
                    count={cardsData?.resolvedQuery}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>
        )
      }

    </DashboardLayout>
  );
}

export default Dashboard;
