import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import React, { useEffect, useState } from 'react';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { MdChat } from "react-icons/md"
import { useMaterialUIController } from 'context';
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import DataTable from "layouts/support/DataTable";
import data from "layouts/assignedFinanceQueries/data";
import Config from "../../config"
import { capitalizeFirstLetter } from 'util/formatDateAndTime';
import MDPagination from 'components/MDPagination';
import MDInput from 'components/MDInput';
import { Formik, Form, Field } from 'formik';
function assignedQueries() {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const supportToken = localStorage.getItem("support_token")
    const { supportUrl } = Config
    const [assignedQueries, setAssignedQueries] = useState([])
    const [status, setStatus] = useState('assigned')
    const [menu, setMenu] = useState(null);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);
    const { columns, rows } = data(assignedQueries);
    const limit = 10
    const [page, setPage] = useState(0)
    // const renderMenu = (
    //     <Menu
    //         id="simple-menu"
    //         anchorEl={menu}
    //         anchorOrigin={{
    //             vertical: "top",
    //             horizontal: "left",
    //         }}
    //         transformOrigin={{
    //             vertical: "top",
    //             horizontal: "right",
    //         }}
    //         open={Boolean(menu)}
    //         onClose={closeMenu}
    //     >
    //         <MenuItem onClick={() => {
    //             setCategory("transaction")
    //             setPage(0)
    //             closeMenu
    //         }}>Transaction Queries</MenuItem>
    //         <MenuItem onClick={() => {
    //             setCategory("technical")
    //             setPage(0)
    //             closeMenu
    //         }}>Technical Queries</MenuItem>
    //     </Menu>
    // );

    async function getAssignedQueries() {
        try {
            const response = await fetch(`${supportUrl}/api/issue/support/assigned?category=transaction&limit=${limit}&skip=${limit * page}&status=${status}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': supportToken
                }
            })
            if (!response) console.log("Something went wrong")
            const res = await response.json()
            setAssignedQueries(res?.responseData)
        } catch (err) {
            console.log("Error Fetching getAssignedQueries: ", err)
        }
    }

    useEffect(() => {
        getAssignedQueries()
    }, [page, status])

    useEffect(() => {
        console.log(status)
    }, [status])
    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox >
                <Card >
                    <MDBox display="flex" justifyContent="space-between" alignItems="center" px={3} py={2}>
                        <MDBox>
                            <MDTypography variant="h6" gutterBottom>
                                Assigned Finance
                            </MDTypography>
                        </MDBox>
                        <MDBox display="flex" justifyContent="space-between" alignItems="center" px={3} py={2}>
                            <MDBox mr={3} display="flex" alignItems="center">
                                <Formik initialValues={{ status: 'assigned' }}>
                                    {({ setFieldValue }) => (
                                        <Form>
                                            <MDBox display="flex" flexDirection="row" alignItems="center">
                                                <MDBox mb={2}>
                                                    <MDTypography variant="caption" color="text" ml={1}>
                                                        Select Status
                                                    </MDTypography>
                                                    <Field
                                                        type="text"
                                                        select
                                                        size="large"
                                                        as={MDInput}
                                                        fullWidth
                                                        value={status}
                                                        onChange={(e) => {
                                                            setStatus(e.target.value); setPage(0)
                                                        }}
                                                        name="status"
                                                        InputProps={{
                                                            classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                        }}
                                                    >
                                                        <MenuItem value="assigned">Assigned</MenuItem>
                                                        <MenuItem value="resolved">Resolved</MenuItem>
                                                    </Field>
                                                </MDBox>
                                            </MDBox>
                                        </Form>
                                    )}
                                </Formik>
                            </MDBox>
                            {/* <MDBox color="text">
                                <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small" onClick={openMenu}>
                                    more_vert
                                </Icon>
                            </MDBox>
                            {renderMenu} */}
                        </MDBox>

                    </MDBox>
                    {/* <MDBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="78vh"
                        width="100%"
                        className="animate__animated animate__fadeIn"
                    >
                        <MDBox
                            mt={-10}
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="space-between"
                            className="space-y-4"
                        >
                            <MDBox display="flex" alignItems="center" justifyContent="center">
                                <MdChat
                                    style={{
                                        fontSize: '3rem', // Adjust the size as needed
                                        color: darkMode ? 'white' : 'grey-300', // Use the desired colors
                                    }}
                                />
                            </MDBox>
                            <MDBox display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                                <MDTypography variant="body2" color={darkMode ? "white" : "textSecondary"}>
                                    {`No ${capitalizeFirstLetter(category)} Queries assigned`}
                                </MDTypography>
                                <MDTypography variant="body2" color={darkMode ? "white" : "textSecondary"}>
                                    Assign a query to get started
                                </MDTypography>
                            </MDBox>
                        </MDBox>
                    </MDBox> */}
                    <MDBox>
                        <DataTable
                            table={{ columns, rows }}
                            showTotalEntries={false}
                            isSorted={false}
                            noEndBorder
                            entriesPerPage={false}
                            canSearch={false}
                        />
                        {
                            page === 0 && assignedQueries && assignedQueries?.length < 10 ? null : (
                                <MDBox
                                    display="flex"
                                    flexDirection={{ xs: "column", sm: "row" }}
                                    justifyContent="space-between"
                                    alignItems={{ xs: "flex-start", sm: "center" }}
                                    mb={3}
                                    ml={1}
                                    mt={1}
                                >
                                    <MDPagination
                                        variant={"gradient"}
                                        color={"info"}
                                    >
                                        <MDPagination item onClick={() => { page > 0 && setPage(page - 1) }}>
                                            <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
                                        </MDPagination>
                                        <MDBox width="5rem" mx={1}>
                                            <MDInput
                                                inputProps={{ type: "number", min: 1 }}
                                                value={page}

                                            />
                                        </MDBox>
                                        {
                                            assignedQueries.length === 10 &&
                                            < MDPagination item onClick={() => { setPage(page + 1) }}>
                                                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
                                            </MDPagination>
                                        }
                                    </MDPagination>
                                </MDBox>
                            )
                        }
                    </MDBox>

                </Card>
            </MDBox>
        </DashboardLayout>
    )
}

export default assignedQueries